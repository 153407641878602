<template>
  <div>
    <CompHeader title="个人资料">
      <template #btn>
        <div class="tips" @click="appFn">
          去摩点 APP 编辑资料<span class="icon-arrow"></span>
        </div>
      </template>
    </CompHeader>
    <div class="ul" v-if="info">
      <div class="li">
        <div class="lable">头像</div>
        <div class="cont">
          <img :src="info ? info.user.icon : ''" alt="" />
        </div>
      </div>
      <div class="li">
        <div class="lable">昵称</div>
        <div class="cont">
          {{ info.user.nickname }}
        </div>
      </div>
      <div class="li">
        <div class="lable">性别</div>
        <div class="cont">
          {{ info.user.gender == 1 ? "男" : (info.user.gender == 2 ?  "女" : "未知") }}
        </div>
      </div>
      <div class="li">
        <div class="lable">生日</div>
        <div class="cont">
          {{ info.user.birthday }}
        </div>
      </div>
      <div class="li">
        <div class="lable">所在城市</div>
        <div class="cont">{{ info.user.live_city || "未填写" }}</div>
      </div>
      <div class="li">
        <div class="lable">个人简介</div>
        <div class="cont">
          {{ info.user.user_content || "未填写" }}
        </div>
      </div>
      <div class="li">
        <div class="lable">摩点ID</div>
        <div class="cont">
          {{ info.user.user_id }}
        </div>
      </div>
      <div class="li">
        <div class="lable">认证情况</div>
        <div class="cont" v-if="userCard">
          <div class="renzheng lan">
            <span class="icon-authentication"></span>
            已认证({{ auth_cate == 11 ? "个人实名" : "企业认证" }})
          </div>
          <div class="bt" @click="toverify">查看认证</div>
        </div>
        <div class="cont" v-else>
          <div class="renzheng">
            <span class="icon-authentication"></span>
            未认证
          </div>
          <div class="bt" @click="toverify">立即认证</div>
        </div>
      </div>
    </div>
    <OpenApp ref="openapp" />
  </div>
</template>

<script>
import domain from '@/service/domain'
// eslint-disable-next-line no-unused-vars
import { userInfo, getAuthInfo } from "@api";

import OpenApp from "@@/common/openApp.vue";
import CompHeader from "@@/common/header.vue";
export default {
  data() {
    return {
      info: null,
      user_id: "",
      auth_cate: 11,
      auth_status: 0,
      history_pass: 0,
      userCard: false,
    };
  },
  components: {
    CompHeader,
    OpenApp,
  },
  mounted() {
    this.user_id = this.$store.state.user.userId;
    this.get_auth_state_info();
    this.user_info();
  },
  methods: {
    appFn() {
      this.$refs.openapp.open();
    },
    toverify() {
      // if (this.auth_cate == 21 && this.auth_status == 200) {
      //   window.open(domain.me + '/u/user_auth_view?uid='+ this.user_id +'&from=cp')
      // } else {
      //   this.$router.push("/identity/verify");
      // }
      if (Number(this.history_pass) == 1) {
        if(this.auth_cate == 11){
          window.open(domain.me + '/u/user_self_edit?uid='+this.user_id+'&from=cp')
        }else{
          window.open(domain.me + '/u/user_auth_view?uid='+this.user_id+'&from=cp')
        }
      }else{
        if(this.auth_status == 0){
            window.open(domain.www + '/identity/type')
        }else if(this.auth_status == 100){
            if(this.history_pass == 1){
              window.open(domain.zhongchou + '/auth/auth_funds_success?type=me')
            }else{
              window.open(domain.zhongchou + '/auth/auth_funds_success')
            }
        }else if(this.auth_status == 201){
          window.open(domain.zhongchou + '/auth/auth_refuse_funds')
        }else{
            if(this.auth_cate == 11){
              window.open(domain.me + '/u/user_self_edit?uid='+this.user_id)
            }else{
              window.open(domain.me + '/u/user_auth_view?uid='+this.user_id)
            }
        }
      }
    },
    async get_auth_state_info() {
      const { data } = await getAuthInfo();
      this.auth_cate = data.auth_cate;
      this.auth_status = data.auth_status
      this.history_pass = data.history_pass
      if (Number(data.history_pass) == 1) {
        this.userCard = true;
      }
    },
    async user_info() {
      const { data } = await userInfo({
        user_id: this.user_id,
      });
      this.info = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.ul {
  padding-top: 30px;
}
.li {
  height: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 127px auto;
  margin-bottom: 40px;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .name {
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #1a1a1a;
    line-height: 16px;
    margin-left: 10px;
  }
  .text {
    font-size: 14px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #7f7f7f;
    line-height: 20px;
  }
  .cont {
    display: flex;
  }
  .renzheng {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #f5a728;
    line-height: 24px;
    span {
      margin-right: 4px;
      width: 21px;
      font-size: 21px;
      display: block;
    }
    &.lan {
      color: #00c4a1;
    }
  }
  .bt {
    margin-left: 10px;
    width: 68px;
    height: 28px;
    background: #f8f8f8;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: PingFang SC-常规体, PingFang SC;
    font-weight: normal;
    color: #7F7F7F;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    &:hover {
      border-color: #1A1A1A;
      color: #1A1A1A;
    }
  }
}
.tips {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-family: PingFang SC-常规体, PingFang SC;
  font-weight: normal;
  color: #7f7f7f;
}
</style>
